import { useContext } from 'react';
import { AuthContext } from '../../App';
import styles from './SideMenu.module.css';
import { SEButton } from '..';

export default function SideMenu() {
  const user = useContext(AuthContext);
  return <>
  <div className={styles.side}>
    <h2>Stargate Dimensions</h2>
    <div className={styles.user}>
      <img src={user?.photos[0].value} alt={user?.displayName}/>
      <div>Hello {user?.displayName}!</div>
    </div>

    <div>
      <SEButton label="Dashboard" />
      <SEButton label="My Grids" />
      <SEButton label="My Faction" />
      <SEButton label="Asgard Refinery" />
      <SEButton label="Tok'ra Stash" />
      <SEButton label="System Lords Bank" />
    </div>

    <div className={styles.bottom}>
      <SEButton label="Logout" onClick={() => {
        window.location.href = "/auth/logout";
      }}/>
    </div>
  </div>
  </>
}
