import sits from "./sits_small.png";

export default function SignInThroughSteam() {
  return <a href="/auth/login" title="Sign in through Steam">
    <img src={sits} alt="Sign in through Steam"/>
  </a>
}

// import styles from './SignInThroughSteam.module.css';

// export default function SignInThroughSteam() {
//   return <form className={styles.sits} method="post" action="/auth/login">
//     <button type="submit" />
//   </form>
// }
