import { Stars, SignIn, SideMenu } from './components/';

import { Canvas } from '@react-three/fiber';

import styles from './App.module.css';
import { createContext } from 'react';
import { SteamUser } from './types/Steam';
import { useFetch } from 'usehooks-ts';

export const AuthContext = createContext<SteamUser | null>(null);

function App() {
  const { data } = useFetch<SteamUser>("/auth/me");

  return (
    <>
      <Canvas className={styles.app} style={{ position: "absolute", left: 0, right: 0, top: 0, bottom: 0 }}
        camera={{ position: [0, 0, 0] }}
        //onPointerMissed={this.onZoomOutClick}
        //raycaster={{ filter: (intersects, state) => this.raycasterFilter(intersects, state) }}
      >
        <ambientLight intensity={0.1} />
        <pointLight position={[0, 0, 40]} intensity={3} />
        <Stars />
      </Canvas>
      {data ? <AuthContext.Provider value={data}>
        <SideMenu />
      </AuthContext.Provider> : <SignIn />}
    </>
  );
}

export default App;
