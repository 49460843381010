import React from 'react';

import styles from './SEButton.module.css';

interface SEButtonProps {
  label: string;
  onClick?: () => void;
}

export default function SEButton(props: SEButtonProps) {
  return (
    <div className={styles.sebutton} onClick={props.onClick}>
      {props.label}
    </div>
  );
}
