import { SignInThroughSteam } from '..';
import styles from './SignIn.module.css';

export default function SignIn() {
  return <>
  <div className={styles.side}>
    <h2>Stargate Dimensions</h2>
    <p>Hello and welcome to Stargate Dimensions player portal. In order to us it's functionality you must login through Steam so we can associate you with player data.</p>
    <div style={{ textAlign: "center"}}>
      <SignInThroughSteam />
    </div>
  </div>
  </>
}
